@font-face {
	font-family: 'rondo';
	src: url('../font/rondo/rondo.eot?#') format('eot'),
		 url('../font/rondo/rondo.svg#rondo') format('svg'),
		 url('../font/rondo/rondo.woff') format('woff'),
		 url('../font/rondo/rondo.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
	font-family: 'rondo';
	font-style: normal;
	font-size: 20pt;
	speak: none;
	/* Enable Ligatures */
	-webkit-font-feature-settings:"liga","dlig";-moz-font-feature-settings:"liga=1, dlig=1";-moz-font-feature-settings:"liga","dlig";-ms-font-feature-settings:"liga","dlig";-o-font-feature-settings:"liga","dlig";
	font-feature-settings:"liga","dlig";
	text-rendering:optimizeLegibility;
	}

/* Icons */

.icon-twitter:before {
	content: "\74\77\69\74\74\65\72";
}
.icon-googleplus:before {
	content: "\67\6f\6f\67\6c\65\70\6c\75\73";
}
.icon-facebook:before {
	content: "\66\61\63\65\62\6f\6f\6b";
}
.icon-email:before {
	content: "\65\6d\61\69\6c";
}
