.page_contact main {
	/* zzz repeated in client-portal START */
	font-size: 14pt;
	
	.arrow {
		transition: all 0.3s;
		transform: rotate(0deg);
	}
	
	h2 {
		cursor: pointer;
	}
	
	h2:not(.hidden) .arrow {
		transform: rotate(90deg);
	}

	.arrow {
		display: inline-block;
		margin-right: 0.3em;
	}
	
	section {
		padding: 1em;
		background-color: lighten($color-lightblue, 20%);
		border-radius: 20px;
		width: 100%;
	}
	/* zzz repeated in client-portal END */
	
	#mapDirectionsRequest {
		margin-top: 1em;
		margin-bottom: 1em;
	}
	
	#mapDirections {
		margin: 0;
		padding: 1em;
		border: 2px solid $color-darkgreen;
		border-radius: 10px;
		display: none;
	}

}