.page_upload-files {
	overflow-y: scroll;
	
	main {
		/* zzz largely repeated in contact START */
		font-size: 14pt;
	
		//.arrow {
		//	transition: all 0.3s;
		//	transform: rotate(90deg);
		//}
		//
		//h2 {
		//	cursor: pointer;
		//}
		//
		//h2:not(.hidden) .arrow {
		//	transform: rotate(0deg);
		//}
		//
		//.arrow {
		//	display: inline-block;
		//	margin-right: 0.3em;
		//}
		//
		//section {
		//	padding: 1em;
		//	margin-bottom: 2em;
		//	background-color: lighten($color-lightblue, 20%);
		//	border-radius: 20px;
		//	width: 100%;
		//	float: left;
		//}
		/* zzz repeated in contact END */
		
		label, input {
			display: inline-block;
			vertical-align: top;
		}
		label {
			width: 95%;
			margin-top: 0.8em;
	
			&:first-child {
				margin-top: 0;
			}
	
			& > span {
				vertical-align: top;
				display: inline-block;
				width: 12em;
				margin-right: 1em;
		
				&.prefix {
					width: 1em;
					margin: 0;
				}
		
				&.prefix + input {
					margin-left: 0.1em;
				}
			}
	
			input, textarea, #files {
				margin: 0 0 0 1.1em;
			}
		}
		
		input, textarea, #files {
			width: calc(95% - 13em);
			
			&[type=number] {
				width: 5em;
			}
		}
	
		fieldset {
			border: 1px solid #ccc;
			border-radius: 20px;
			width: 100%;
			padding: 0.8em 0 0.8em 0.8em;
			margin-bottom: 0.8em;
			
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	
		/* file uploads START */
		#newBatch {
			display: none;
			margin-right: 1em;
		}
		.dropzone .dz-preview .dz-image {
			width: 100px;
			height: 100px;
		}
			
		.dz-preview * {
			cursor: pointer !important;
		}
		.dz-progress {
			display: none;
		}
		
		#uploaded {
			display: none;
			margin-top: 1em;
			
			ul {
				display: inline-block;
				margin: 0;
			}
		}
		/* file uploads END */
	
		/* processing modal START */
		.spinner {
			width: 40px;
			height: 40px;
			position: relative;
			margin: 0.8em;
			display: inline-block;
		}
		
		.double-bounce1, .double-bounce2 {
			width: 95%;
			height: 95%;
			border-radius: 50%;
			background-color: $color-darkgreen;
			opacity: 0.6;
			position: absolute;
			top: 0;
			left: 0;
			
			animation: sk-bounce 2.0s infinite ease-in-out;
		}
		
		.double-bounce2 {
			animation-delay: -1.0s;
		}
		
		@keyframes sk-bounce {
			0%, 100% { 
				transform: scale(0.0);
			} 50% { 
				transform: scale(1.0);
			}
		}
		
		/* processing modal END */

		.note {
			margin-left: 14em;

			span {
				font-size: 70%;
				font-style: italic;
				font-weight: normal;
				color: $color-darkgreen;
			}
		}
	}
}