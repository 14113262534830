.page_team main {

	.members {
		overflow: hidden;		
		
		h3 {
			font-size: 14pt;
			padding: 5px;
			margin: 0 0 0 0;
			color: white;
			background-color: $color-darkgreen;
			border-radius: 5px;
		}
	}
	
	
	.bio {
		color: #222;
		width: 90%;
		margin-left: 5%;
		margin-top: 1em;
	}
		
	/*
	.pointer {
		position: relative;
	}
	
	.pointer:after {
		content: "\25b2";
		width: 24%;
		display: block;
		font-family: Arial, "Times New Roman";
		font-size: 60pt;
		text-align: center;
		line-height: 50pt;
	}
	*/
	
	article.medium {
		width: 100%;

		a {
			width: 30%;
			display: inline-block;
			vertical-align: top;
			padding-top: 1.5em;
		}
		
		p {
			width: 65%;
			display: inline-block;
			margin-left: 4%;
		}
	}
	
	article.small {
		width: 100%;
		
		a {
			width: 80%;
			display: block;
			margin-left: 10%;
			padding-top: 0.5em;
		}
		
		p {
			width: 90%;
			display: block;
			margin-left: 5%;
			font-size: 12pt;
		}
	}
}