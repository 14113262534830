@import url(https://fonts.googleapis.com/css?family=Cinzel:700|Tinos:700italic|Open+Sans);

$color-lightblue: #64cdc9;
$color-darkgreen: #134b00;

$font-body: "Open Sans", sans-serif;

* {
	box-sizing: border-box;
}

.clear {
	overflow: auto;
}

a {
	text-decoration: none;
	transition: 0.3s;
	color: $color-darkgreen;
	
	&:hover {
		color: $color-lightblue;
	}
}

html {
	background-color: $color-darkgreen;
	padding: 0;
}

body {
	position: relative;
	background-color: white;
	overflow: auto;
	font-family: $font-body;
	font-weight: bold;
	color: #222;
	max-width: 1400px;
	margin-left: auto;
	margin-right: auto;
	/* border-radius: 0 20px 20px 20px; */
}

body:before {
	content: ' ';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	opacity: 0.1;
	background-image: url('../img/bg.png');
	background-repeat: repeat;
	border-radius: 20px;
}

body > * {
	/* z-index: 4; */
	position: relative;
}

body > nav {
	position: absolute;
	top: 0;
	width: 20%;
	left: 0;
	bottom: 0;
	background-color: #134b00;
	padding: 0 1em;
	
	img {
		margin-left: auto;
		margin-right: auto;
		padding: 15px 0 75px 0;
		width: 65%;
		display: block;
	}
	
	a {
		display: block;
		text-transform: uppercase;
		font-size: 1.65vw;
		line-height: 2.3;
		color: white;
		font-weight: normal;
		text-align: center;
		
		&:hover {
			color: $color-lightblue;
		}
		
		&.current {
			color: $color-lightblue;
			/* text-shadow: 2px 2px 3px rgba($color-darkgreen, 1); */
		}
	}
}

header, main, footer {
	margin-left: 20%;
	padding-left: 1.5em;
	padding-right: 1.5em;
	text-align: left;
}

header {
	padding-top: 5px;
	padding-bottom: 30px;
	
	h1 {
		padding-top: 0px;
		margin: 0;
		font-family: Cinzel, serif;
		font-size: 3em;
		color: $color-darkgreen;
	}
	
	h2 {
		padding: 0 0 0 20px;
		margin: 0;
		font-family: Tinos, serif;
		font-size: 2em;
		color: darken($color-lightblue, 20%);
	}
	
	h3 {
		padding: 0 0 0 20px;
		margin: 0;
		font-family: Tinos, serif;
		font-size: 1.4em;
		color: darken($color-lightblue, 20%);
		
		a {
			color: inherit;
			
			&:hover {
				color: $color-darkgreen;
			}
		}
	}
}

main {
	overflow: auto;
	min-height: 600px;
}

footer {
	text-align: center;
	margin-top: 50px;
	padding-bottom: 25px;
	margin-bottom: 0;
	font-family: $font-body;
	transition: 0.3s;
	
	&, a {
		color: $color-darkgreen;
	}
	
	a:hover {
		color: $color-lightblue;
	}
}