.page_services main {

	.services {
		overflow: hidden;		
		
		a {
			font-size: 0;
	
			float: left;
			width: 24%;
			margin: 0 1% 0 0;
			padding: 0;
			border: 3px solid transparent;
			border-radius: 7px;
			overflow: hidden;
			color: $color-darkgreen;
			
			&:hover {
				color: $color-lightblue;
			}
			
			&.current {
				border-color: $color-darkgreen;
			}
			
			img {
				width: 100%;
			}
	
			h2 {
				text-align: center;
				font-size: 14pt;
				padding: 5px;
				margin: 0 0 0 0;
			}
			
			&.current h2 {
				color: white;
				background-color: $color-darkgreen;
			}
		}
	}
	
	
	.details {
		color: #222;
		width: 90%;
		margin-left: 5%;
		margin-top: 1em;
	}
		
}