.page_index main {
	nav {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		background-color: $color-darkgreen;
		padding: 10px;
		margin: 0 0 0 -1.5em;
		width: calc(100% + 3em);
		
		a {
			display: inline-flex;
			color: white;
			margin: 0 1em;
			line-height: 1.5;
			
			&:hover {
				color: $color-lightblue;
			}
		}
	}
	
	.bx-wrapper, .hero, .bx-wrapper img {
		box-shadow: unset;
		border: 0;
		margin-left: -1.5em;
		width: calc(100% + 3em) !important;
		margin-bottom: 0.5em;
		max-width: unset !important;
	}
	p {
		font-size: 15pt;
	}
	
	.ask-the-engineer {
		margin: inherit 20%;
		padding: 1% 5%;
		background-color: lighten($color-lightblue, 20%);
		border-radius: 5px;
		
		h3 {
			font-size: 170%;
		}
		
		.questions {
			line-height: 170%;
			font-style: italic;
		}
	}
}